@use './config/' as *;
@forward './main.scss';
@forward './events/fourstar-shoswcase';
@forward "./fixtures/results";
@forward "./standings/common-ranking-results";

.waf-fixtures{
    background-color: var(--neutral-0);

    .tab{
        &-section{
            @extend %relative;
            @extend %bg-transparent;
            @extend %p-0;
            .tabs{
                border-bottom: 0;
                margin-inline: 0;
                padding-inline: 0;
                overflow-x: auto;
            }
            .table{
                &-header{
                    background-color: var(--neutral-0);
                }
            }
            .nation {
                flex-basis: 25%;
            }
        }
        &s{
            justify-content: flex-start;
        }
        &-name{
            width: max-content;
        }
    }

    .site-search-form{
        .search-input{
            @extend %body-s;
        }
    }
    .table{
        &-header{
            @include position(10.7rem, null, null, 0);
        }
        &-data {
            @extend %py-4;
        }
    }
}


@include mq(col-tablet){
    .waf-fixtures{
        .waf-head {
            height: unset;
        }
        .tab{
            &-text{
                font-size: 1.6rem;
            }
            &-section{
                padding: 0;
                .tabs{
                    margin-bottom: var(--space-14);
                }
                .table{
                    &-header{
                        padding: 0;
                        top: 0;
                        left: unset;
                        right: 0;
                        height: 6.4rem;
                    }
                }
                .form-wrapper{
                    margin-bottom: 0;
                    display: block;
                    max-width: 50rem;
                    width: calc(43% - 7.2rem);
                    @include position(0, 7.2rem);
                }
                .fname, .lname, .yob, .nation{
                    flex-basis: 22.5%;
                }
                .start{
                    flex-basis: 10%;
                }
                .team{
                    flex-basis: 67.5%;
                }
                .nation {
                    flex-basis: 15%;
                    .text {
                        display: flex;
                    }
                }
            }
            &s{
                justify-content: flex-start;
                max-width: 56.5%;
            }
        }


        .site-search-form{
            width: 100%;
            .search-input{
                font-size: 1.6rem;
                height: 6.4rem;
            }
        }
    }
}

@include mq(col-lg){
    .waf-fixtures{
        .tab{
            &s{
                max-width: 38.5%;
            }
            &-section{
                .table{
                    &-actions{
                        position: static;
                        transform: unset;
                    }
                    &-header{
                        padding-inline: 0;
                        position: absolute;
                        justify-content: space-between;
                    }
                }
                .form-wrapper{
                    width: calc(61% - 9.8rem);
                    @include position(0, 9.8rem);
                }
            }
        }
    }
}